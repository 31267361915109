import axios from 'axios'
import router from '@/router'
import store from '@/store'
import { baseconfig } from "@/config.js";
import { Message } from 'element-ui'

let ShowMsg = null
/** 
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
 const toLogin = () => {
  if (router.currentRoute.fullPath.indexOf('login') == -1) {
      router.replace({
          path: '/login',
          query: {
              backurl: router.currentRoute.fullPath
          }
      })
  }

}

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 36000
})
// request拦截器
service.interceptors.request.use(config => {
  config.headers.common['Authorization'] = store.getters.token && ('Bearer ' + store.getters.token)
  // get请求映射params参数
  // if (config.method === 'get' && config.params) {
  //   let url = config.url + '?';
  //   for (const propName of Object.keys(config.params)) {
  //     const value = config.params[propName];
  //     var part = encodeURIComponent(propName) + "=";
  //     if (value !== null && typeof(value) !== "undefined") {
  //       if (typeof value === 'object') {
  //         for (const key of Object.keys(value)) {
  //           if (value[key] !== null && typeof (value[key]) !== 'undefined') {
  //             let params = propName + '[' + key + ']';
  //             let subPart = encodeURIComponent(params) + '=';
  //             url += subPart + encodeURIComponent(value[key]) + '&';
  //           }
  //         }
  //       } else {
  //         url += part + encodeURIComponent(value) + "&";
  //       }
  //     }
  //   }
  //   url = url.slice(0, -1);
  //   config.params = {};
  //   config.url = url;
  // }
  return config
}, error => {
    Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(function(response) {
  // 对响应数据做点什么
  // if (response.status === 200) {
  //     return Promise.resolve(response)
  // } else {
  //     return Promise.reject(response)
  // }
  //NProgress.done()

  return response;
}, function(error) {
  // 对响应错误做点什么
  //NProgress.done()
  switch (error.response.status) {
      case 401:
          {
            console.log(error.response, 654321)
            if (ShowMsg) return;
            ShowMsg = true;
            Message.error('鉴权失败，请重新登录！');
            store.dispatch('admin/removeToken')
            store.commit('admin/SET_CODE', '')
            if (router.currentRoute.fullPath.indexOf('login') == -1) {
              router.replace({
                path: '/login',
                query: {
                  backurl: router.currentRoute.fullPath
                }
              })
              setTimeout(() => {
                ShowMsg = null
              }, 1000)
            }
            // this.$store.dispatch('admin/removeToken')
            // this.$store.commit('admin/SET_CODE', '')
            // this.$router.replace('/login')
          }
          break;
      case 404:
      case 500:
          break;
      default:
          break;

  }
  return Promise.reject(error)
});

export default service
