var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "addOrEditModel",
          attrs: {
            "label-width": "120px",
            model: _vm.addOrEditModel,
            rules: _vm.addRules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "问题标题", prop: "questionTitle" } },
            [
              _c("el-input", {
                attrs: { size: "medium", placeholder: "请输入问题标题" },
                model: {
                  value: _vm.addOrEditModel.questionTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.addOrEditModel, "questionTitle", $$v)
                  },
                  expression: "addOrEditModel.questionTitle",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "问题类型", prop: "questionType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择问题类型" },
                  model: {
                    value: _vm.addOrEditModel.questionType,
                    callback: function ($$v) {
                      _vm.$set(_vm.addOrEditModel, "questionType", $$v)
                    },
                    expression: "addOrEditModel.questionType",
                  },
                },
                _vm._l(_vm.searchForm.questionTypeList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提示图片", prop: "promptImage" } },
            [
              _c("UploadImageNewOne", {
                attrs: { imgList: _vm.addOrEditModel.promptImage, limit: 9 },
                on: {
                  "update:imgList": function ($event) {
                    return _vm.$set(_vm.addOrEditModel, "promptImage", $event)
                  },
                  "update:img-list": function ($event) {
                    return _vm.$set(_vm.addOrEditModel, "promptImage", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提示内容", prop: "promptContent" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  size: "medium",
                  placeholder: "请输入提示内容",
                },
                model: {
                  value: _vm.addOrEditModel.promptContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.addOrEditModel, "promptContent", $$v)
                  },
                  expression: "addOrEditModel.promptContent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否显示", prop: "isAvailable", required: "" } },
            [
              _c("el-checkbox", {
                attrs: { "false-label": 0, "true-label": 1 },
                model: {
                  value: _vm.addOrEditModel.isAvailable,
                  callback: function ($$v) {
                    _vm.$set(_vm.addOrEditModel, "isAvailable", $$v)
                  },
                  expression: "addOrEditModel.isAvailable",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否是标签", prop: "isLabel", required: "" } },
            [
              _c("el-checkbox", {
                attrs: { "false-label": 0, "true-label": 1 },
                model: {
                  value: _vm.addOrEditModel.isLabel,
                  callback: function ($$v) {
                    _vm.$set(_vm.addOrEditModel, "isLabel", $$v)
                  },
                  expression: "addOrEditModel.isLabel",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sort" } },
            [
              _c("el-input", {
                attrs: { type: "number", placeholder: "请填写排序" },
                model: {
                  value: _vm.addOrEditModel.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.addOrEditModel, "sort", $$v)
                  },
                  expression: "addOrEditModel.sort",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer rigth",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
              on: { click: _vm.clickAddOrEditConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }