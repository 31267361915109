import BaseModule from './utils/BaseModule'
import requestNew from './utils/requestNew'
class BaseManager extends BaseModule {
	constructor() {
		super()
	}

	//注销设置列表
	logoutSettingList(param) {
		return this.get('web/logoutSetting/list', param)
	}
	//添加注销设置
	logoutSettingSave(param) {
		return this.post('web/logoutSetting/save', param)
	}
	//修改注销设置
	logoutSettingUpdate(param) {
		return this.post('web/logoutSetting/update', param)
	}
	//删除注销设置
	logoutSettingRemove(param) {
		return this.post('web/logoutSetting/remove', param)
	}

	//查看说明设置列表
	explainSettingList(param) {
		return this.get('web/explainSetting/list', param)
	}
	//修改说明设置内容
	explainSettingUpdate(param) {
		return this.post('web/explainSetting/update', param)
	}

	//费率设置
	rateSettingList(param) {
		return this.get('web/rateSetting/list', param)
	}
	//费率设置修改
	rateSettingUpdate(param) {
		return this.post('web/rateSetting/update', param)
	}

	//区域查询
	regionList(param) {
		return this.get('web/regionInfo/list', param)
	}
	//新增区域
	regionSave(param) {
		return this.post('web/regionInfo/save', param)
	}
	//修改区域
	regionUpdate(param) {
		return this.post('web/regionInfo/update', param)
	}
	//删除区域
	regionRemove(param) {
		return this.post('web/regionInfo/remove', param)
	}

	//版本信息
	versionInfoList(param) {
		return this.get('web/versionInfo/list', param)
	}
	//版本信息修改
	versionInfoUpdate(param) {
		return this.post('web/versionInfo/update', param)
	}


	//日志
	listvList(param) {
		return this.get('common/log/list', param)
	}
	//批量删除日志
	batchRemove(param) {
		return this.post('common/log/batchRemove', param)
	}
	//日志删除
	listvRemove(param) {
		return this.post('common/log/remove', param)
	}


	//系统消息列表
	messageList(param) {
		return this.get('web/systemMessageInfo/list', param)
	}
	//系统消息新增
	messageSave(param) {
		return this.post('web/systemMessageInfo/save', param)
	}
	//系统消息删除
	messageRemove(param) {
		return this.post('web/systemMessageUserInfo/remove', param)
	}


	//邀请任务列表
	taskList(param) {
		return this.get('web/userInvitationTaskInfo/list', param)
	}
	//新增邀请任务
	taskSave(param) {
		return this.post('web/userInvitationTaskInfo/save', param)
	}
	//修改邀请任务
	taskUpdate(param) {
		return this.post('web/userInvitationTaskInfo/update', param)
	}
	//删除邀请任务
	taskRemove(param) {
		return this.post('web/userInvitationTaskInfo/remove', param)
	}


	//广告列表
	advertList(param) {
		return this.get('web/advertInfo/list', param)
	}
	//新增广告
	advertInfoSave(param) {
		return this.post('web/advertInfo/save', param)
	}
	//修改广告
	advertInfoUpdate(param) {
		return this.post('web/advertInfo/update', param)
	}
	//删除广告
	advertInfoRemove(param) {
		return this.post('web/advertInfo/remove', param)
	}


	//问诊类型列表
	consultationTypeList(param) {
		return this.get('/web/consultationTypeInfo/list', param)
	}
	//新增问诊类型
	async saveConsultationTypeInfo(param) {
		return await requestNew({
			url: "/web/consultationTypeInfo/save",
			method: 'post',
			data: param
		})
	}
	//修改问诊类型
	async updateConsultationTypeInfo(param) {
		return await requestNew({
			url: "/web/consultationTypeInfo/update",
			method: 'post',
			data: param
		})
	}
	//删除问诊类型
	removeConsultationTypeInfo(param) {
		return this.post('web/consultationTypeInfo/remove', param)
	}



	//设备管理列表
	terminalList(param) {
		return this.get('/web/terminalInfo/list', param)
	}
	//删除设备
	terminalRemove(param) {
		return this.post('/web/terminalInfo/remove', param)
	}


	//快捷语列表
	quickPhrasesList(param) {
		return this.get('/web/quickPhrasesInfo/list', param)
	}
	//删除快捷语
	quickPhrasesRemove(param) {
		return this.post('/web/quickPhrasesInfo/remove', param)
	}


	//添加实名认证
	saveRealNameAuthenticationInfo(param) {
		return this.post('web/realNameAuthenticationInfo/save', param)
	}
	//根据用户ID查询是否进行了实名认证
	searchSmrzFlag(param) {
		return this.get('web/realNameAuthenticationInfo/searchSmrzFlagByUserId', param)
	}

}

export default new BaseManager()


import request from '@/request/utils/newrequest.js'
import { baseconfig } from "@/config.js";

//设备管理
export const terminalModule = {
    // 新增设备
    async terminalSave(param) {
        return await request({
            url: baseconfig.baseUrl + "/web/terminalInfo/save",
            method: 'post',
            data: param
        })
    },
    // 修改设备
	async terminalUpdate(param) {
        return await request({
            url: baseconfig.baseUrl + "/web/terminalInfo/update",
            method: 'post',
            data: param
        })
    },
}

//快捷语管理
export const quickPhrasesModule = {
    // 新增快捷语
    async quickPhrasesSave(param) {
        return await request({
            url: baseconfig.baseUrl + "/web/quickPhrasesInfo/save",
            method: 'post',
            data: param
        })
    },
    // 修改快捷语
	async quickPhrasesUpdate(param) {
        return await request({
            url: baseconfig.baseUrl + "/web/quickPhrasesInfo/update",
            method: 'post',
            data: param
        })
    },
}