<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-input type="text" placeholder="请输入问题标题" v-model="query.questionTitle" clearable=""></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="query.questionType" placeholder="请选择问题类型" clearable>
                        <el-option v-for="item in searchForm.questionTypeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item v-if="permission.saveQuestionnaireQuestionInfo">
                    <el-button type="primary" plain @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'userId'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="userId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="questionTitle" label="问题标题" align="left" min-width="120"></el-table-column>
                <el-table-column prop="promptContent" label="提示内容" align="left" min-width="240"></el-table-column>
                <el-table-column label="问题选项" align="center" width="90">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showContentHandle(scope.row, 1)"
                            v-if="permission.lookQuestionnaireQuestionAnswerList">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="提示图片" align="center" width="90">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showContentHandle(scope.row, 2)"
                            v-if="scope.row.imagesInfoDOS && scope.row.imagesInfoDOS.length">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="问题类型" align="center" width="90">
                    <template slot-scope="scope">
                        {{ scope.row.questionType | getStateName(searchForm.questionTypeList) }}
                    </template>
                </el-table-column>
                <el-table-column prop="isLabel" label="是否是标签" align="center" width="90">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isLabel == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="isAvailable" label="是否可用" align="center" width="90">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isAvailable == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="sort" label="排序" align="center" width="90">
                </el-table-column>
                <el-table-column prop="createTime" label="添加时间" align="center" width="140">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120" fixed="right" align="center">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateQuestionnaireQuestionInfo">
                            <el-button class="success" icon="el-icon-edit-outline" type="text"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeQuestionnaireQuestionInfo">
                            <el-popconfirm title="您确定要删除么？" icon="el-icon-info" icon-color="red"
                                @confirm="clickDelInfo(scope.row, scope.$index)">
                                <el-button icon="el-icon-delete" slot="reference" type="text"
                                    class="danger"></el-button>
                            </el-popconfirm>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="选择" placement="top"
                            v-if="permission.selectQuestionnaireQuestionList">
                            <el-button icon="el-icon-circle-check" type="text" class="success"
                                @click="selectQuestionHandle(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal"></el-pagination>
        </div>
        <!-- 添加/修改 -->
        <el-dialog :title="addOrEditTitle" :visible.sync="dialogAddOrEditVisible" :modal="false"
            :destroy-on-close="true" fullscreen>
            <AddOrEditQuestionInfo v-if="dialogAddOrEditVisible"
                @addSuccess="refreshData(), dialogAddOrEditVisible = false" :dataInfo="addOrEditModel" />
        </el-dialog>
        <!-- 问题选项 -->
        <el-dialog title="问题选项" :visible.sync="dialogQuestionsAnswerInfoVisible" :modal="false" :destroy-on-close="true"
            fullscreen>
            <QuestionsAnswerInfo v-if="dialogQuestionsAnswerInfoVisible" :questionId="addOrEditModel.id" />
        </el-dialog>
        <!-- 图片 -->
        <el-image ref="preview" style="width: 0px;height: 0px;" :preview-src-list="goodsGalleryList"></el-image>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import { getList } from '@/tools/getList.js'
import questionnaireManager from '@/request/questionnaireManager.js'
import AddOrEditQuestionInfo from '@/components/page/questionnaire/question/sub/AddOrEditQuestionInfo.vue'
import QuestionsAnswerInfo from '@/components/page/questionnaire/question/sub/QuestionsAnswerInfo.vue'

export default {
    mixins: [getList],
    // import引入的组件需要注入到对象中才能使用
    components: {
        AddOrEditQuestionInfo,
        QuestionsAnswerInfo
    },
    data() {
        // 这里存放数据
        return {
            requst: {
                module: questionnaireManager,
                url: 'questionnaireQuestionList',
            },
            query: {
                questionTitle: '',
                questionType: ''
            },


            searchForm: {
                questionTypeList: [{
                    value: 1,
                    label: '单选'
                }, {
                    value: 2,
                    label: '多选'
                }],
                isLabelList: [{
                    value: 0,
                    label: '否'
                }, {
                    value: 1,
                    label: '是'
                }],
            },
            // 添加修改
            addOrEditTitle: '',
            addOrEditModel: null,
            dialogAddOrEditVisible: false,
            // 答案
            dialogQuestionsAnswerInfoVisible: false,

            //查看图片
            goodsGalleryList: [],
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        onAdd() {
            this.addOrEditModel = null
            this.addOrEditTitle = '添加问卷问题'
            this.dialogAddOrEditVisible = true
        },
        clickEditInfo(row) {
            this.addOrEditModel = row
            this.addOrEditTitle = '修改问卷问题'
            this.dialogAddOrEditVisible = true
        },
        showContentHandle(row, type) {
            switch (type) {
                case 1:
                    {
                        this.addOrEditModel = row
                        this.dialogQuestionsAnswerInfoVisible = true
                    }
                    break;
                case 2:
                    {
                        this.goodsGalleryList = row.imagesInfoDOS.map(obj => {
                            return obj.sourceImg
                        })
                        if (this.goodsGalleryList.length) {
                            this.$nextTick(() => {
                                this.$refs.preview.clickHandler()
                            })
                        }
                    }
                    break;

                default:
                    break;
            }
        },
        async clickDelInfo(row, index) {
            try {
                let { code, message } = await questionnaireManager.removeQuestionnaireQuestionInfo({
                    id: row.id,
                })
                if (code === 200) {
                    this.$message({ message: message, type: 'success', })
                    this.delRefresh(index)
                }
            } catch (error) {
                console.log(error)
            }
        },
        selectQuestionHandle(row){
            this.$emit('changeSelectQuestion',row)
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped></style>
