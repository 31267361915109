var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "addOrEditModel",
          attrs: {
            "label-width": "120px",
            model: _vm.addOrEditModel,
            rules: _vm.addRules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "问题答案名称", prop: "questionAnswerName" } },
            [
              _c("el-input", {
                attrs: { size: "medium", placeholder: "请输入问题答案名称" },
                model: {
                  value: _vm.addOrEditModel.questionAnswerName,
                  callback: function ($$v) {
                    _vm.$set(_vm.addOrEditModel, "questionAnswerName", $$v)
                  },
                  expression: "addOrEditModel.questionAnswerName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提示图片", prop: "promptImage" } },
            [
              _c("UploadImageNewOne", {
                attrs: { imgList: _vm.addOrEditModel.promptImage, limit: 9 },
                on: {
                  "update:imgList": function ($event) {
                    return _vm.$set(_vm.addOrEditModel, "promptImage", $event)
                  },
                  "update:img-list": function ($event) {
                    return _vm.$set(_vm.addOrEditModel, "promptImage", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提示内容", prop: "questionAnswerContent" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  size: "medium",
                  placeholder: "请输入提示内容",
                },
                model: {
                  value: _vm.addOrEditModel.questionAnswerContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.addOrEditModel, "questionAnswerContent", $$v)
                  },
                  expression: "addOrEditModel.questionAnswerContent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sort" } },
            [
              _c("el-input", {
                attrs: { size: "medium", placeholder: "请输入排序" },
                on: {
                  input: function ($event) {
                    _vm.addOrEditModel.sort = _vm.addOrEditModel.sort.replace(
                      /[^0-9]/g,
                      ""
                    )
                  },
                },
                model: {
                  value: _vm.addOrEditModel.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.addOrEditModel, "sort", $$v)
                  },
                  expression: "addOrEditModel.sort",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer rigth",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
              on: { click: _vm.clickAddOrEditConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }