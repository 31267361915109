<template>
    <div>
        <el-form label-width="120px" ref="addOrEditModel" :model="addOrEditModel" :rules="addRules">
            <el-form-item label="问题答案名称" prop="questionAnswerName">
                <el-input size="medium" v-model="addOrEditModel.questionAnswerName" placeholder="请输入问题答案名称"></el-input>
            </el-form-item>
            <el-form-item label="提示图片" prop="promptImage">
                <UploadImageNewOne :imgList.sync="addOrEditModel.promptImage" :limit="9"></UploadImageNewOne>
            </el-form-item>
            <el-form-item label="提示内容" prop="questionAnswerContent">
                <el-input type="textarea" size="medium" v-model="addOrEditModel.questionAnswerContent"
                    placeholder="请输入提示内容"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
                <el-input size="medium" v-model="addOrEditModel.sort" placeholder="请输入排序"
                    @input="addOrEditModel.sort = addOrEditModel.sort.replace(/[^0-9]/g, '')"></el-input>
            </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer rigth">
            <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import UploadImageNewOne from "@/components/common/subgroup/UploadImageNewOne.vue";
import questionnaireManager from '@/request/questionnaireManager.js'
export default {
    // import引入的组件需要注入到对象中才能使用
    components: {
        UploadImageNewOne
    },
    props: ['dataInfo', 'questionId'],
    data() {
        // 这里存放数据
        return {
            requst: {
                module: questionnaireManager,
                addUrl: 'saveQuestionnaireQuestionAnswerInfo',
                updateUrl: 'updateQuestionnaireQuestionAnswerInfo',
            },
            query: {
            },

            addOrEditBtnLoading: false,
            addOrEditModel: {
                questionId: this.questionId,
                id: 0,
                questionAnswerName: '',
                promptImage: [],
                sort: '',
                questionAnswerContent: '',
            },
            addRules: {
                questionAnswerName: [
                    { required: true, message: '请输入问题答案名称', trigger: 'blur' },
                ],
                sort: [
                    { required: true, message: '请输入排序', trigger: 'blur' },
                ],
            },
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        if (this.dataInfo) {
            this.addOrEditModel.id = this.dataInfo.id
            this.addOrEditModel.questionAnswerName = this.dataInfo.questionAnswerName
            this.addOrEditModel.promptImage = this.dataInfo.list ? this.dataInfo.list : []
            this.addOrEditModel.sort = this.dataInfo.sort
            this.addOrEditModel.questionAnswerContent = this.dataInfo.questionAnswerContent
        }
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        clickAddOrEditConfirm() {
            this.$refs.addOrEditModel.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        var obj = {
                            "questionId": this.addOrEditModel.questionId,
                            "sort": this.addOrEditModel.sort,
                            "questionAnswerName": this.addOrEditModel.questionAnswerName,
                            "questionAnswerContent": this.addOrEditModel.questionAnswerContent,
                            list: [],
                        }
                        if (this.addOrEditModel.id) {
                            obj.id = this.addOrEditModel.id
                        }
                        this.addOrEditModel.promptImage.forEach(imgObj => {
                            obj.list.push({
                                "answerId": this.addOrEditModel.id,
                                "bigImg": imgObj.bigImg,
                                "sourceImg": imgObj.sourceImg,
                                "thumbImg": imgObj.thumbImg
                            })
                        })


                        let dataValue = JSON.parse(JSON.stringify(obj))
                        let data = await this.requst.module[this.addOrEditModel.id > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
                        if (data.code == 200) {
                            this.$message({ message: data.message, type: 'success', })
                            this.$emit('addSuccess')
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }
                } else {
                    return false
                }

            })
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped>
.rigth {
    text-align: right;
}
</style>
