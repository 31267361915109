import { render, staticRenderFns } from "./QuestionsAnswerInfo.vue?vue&type=template&id=2703272d&scoped=true"
import script from "./QuestionsAnswerInfo.vue?vue&type=script&lang=js"
export * from "./QuestionsAnswerInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2703272d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/ap/jenkins/workspace/prod-ykd360-admin-front(医可达旧管理端)/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2703272d')) {
      api.createRecord('2703272d', component.options)
    } else {
      api.reload('2703272d', component.options)
    }
    module.hot.accept("./QuestionsAnswerInfo.vue?vue&type=template&id=2703272d&scoped=true", function () {
      api.rerender('2703272d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/page/questionnaire/question/sub/QuestionsAnswerInfo.vue"
export default component.exports