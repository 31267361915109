import BaseModule from './utils/BaseModule'
import requestNew from './utils/requestNew'
class BaseManager extends BaseModule {
    constructor() {
        super()
    }

    // 问题列表
    questionnaireQuestionList(param) {
        return this.get('/web/questionnaireQuestionInfo/list', param)
    }

    // 添加问题
    async saveQuestionnaireQuestionInfo(param) {
        return await requestNew({
            url: "/web/questionnaireQuestionInfo/save",
            method: 'post',
            data: param
        })
    }

    // 修改问题
    async updateQuestionnaireQuestionInfo(param) {
        return await requestNew({
            url: "/web/questionnaireQuestionInfo/update",
            method: 'post',
            data: param
        })
    }

    // 删除问题
    removeQuestionnaireQuestionInfo(param) {
        return this.post('/web/questionnaireQuestionInfo/remove', param)
    }


    // 问题选项列表
    questionnaireQuestionAnswerList(param) {
        return this.get('/web/questionnaireQuestionAnswerInfo/list', param)
    }

    // 添加问题选项
    async saveQuestionnaireQuestionAnswerInfo(param) {
        return await requestNew({
            url: "/web/questionnaireQuestionAnswerInfo/save",
            method: 'post',
            data: param
        })
    }

    // 修改问题选项
    async updateQuestionnaireQuestionAnswerInfo(param) {
        return await requestNew({
            url: "/web/questionnaireQuestionAnswerInfo/update",
            method: 'post',
            data: param
        })
    }

    // 删除问题选项
    removeQuestionnaireQuestionAnswerInfo(param) {
        return this.post('/web/questionnaireQuestionAnswerInfo/remove', param)
    }



    
    // 问卷模版列表
    surveyQuestionnaireTemplateList(param) {
        return this.get('/web/surveyQuestionnaireTemplateInfo/list', param)
    }

    // 添加问卷模版
    async saveSurveyQuestionnaireTemplateInfo(param) {
        return await requestNew({
            url: "/web/surveyQuestionnaireTemplateInfo/save",
            method: 'post',
            data: param
        })
    }

    // 修改问卷模版
    async updateSurveyQuestionnaireTemplateInfo(param) {
        return await requestNew({
            url: "/web/surveyQuestionnaireTemplateInfo/update",
            method: 'post',
            data: param
        })
    }

    // 删除问卷模版
    removeSurveyQuestionnaireTemplateInfo(param) {
        return this.post('/web/surveyQuestionnaireTemplateInfo/remove', param)
    }


    // 模版问题列表
    surveyQuestionnaireTemplateQuestionList(param) {
        return this.get('/web/surveyQuestionnaireTemplateQuestionInfo/list', param)
    }

    // 添加模版问题
    async saveSurveyQuestionnaireTemplateQuestionInfo(param) {
        return await requestNew({
            url: "/web/surveyQuestionnaireTemplateQuestionInfo/save",
            method: 'post',
            data: param
        })
    }

    // 修改模版问题
    async updateSurveyQuestionnaireTemplateQuestionInfo(param) {
        return await requestNew({
            url: "/web/surveyQuestionnaireTemplateQuestionInfo/update",
            method: 'post',
            data: param
        })
    }

    // 删除模版问题
    removeSurveyQuestionnaireTemplateQuestionInfo(param) {
        return this.post('/web/surveyQuestionnaireTemplateQuestionInfo/remove', param)
    }
    
}

export default new BaseManager()