var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.getQuestionnaireQuestionAnswerList },
                    },
                    [_vm._v("刷新")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.permission.saveQuestionnaireQuestionAnswerInfo
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "编号", align: "center", width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "questionAnswerName",
                  label: "问题答案名称",
                  align: "left",
                  "min-width": "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "questionAnswerContent",
                  label: "问题答案提示内容",
                  align: "left",
                  "min-width": "240",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "提示图片", align: "center", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.list && scope.row.list.length
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showContentHandle(scope.row, 1)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sort",
                  label: "排序",
                  align: "center",
                  width: "90",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "添加时间",
                  align: "center",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatDate")(scope.row.createTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "90", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.permission.updateQuestionnaireQuestionAnswerInfo
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "编辑",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    icon: "el-icon-edit-outline",
                                    type: "text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickEditInfo(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.removeQuestionnaireQuestionAnswerInfo
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "删除",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您确定要删除么？",
                                      icon: "el-icon-info",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.clickDelInfo(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "danger",
                                      attrs: {
                                        slot: "reference",
                                        icon: "el-icon-delete",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.addOrEditTitle,
            visible: _vm.dialogAddOrEditVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAddOrEditVisible = $event
            },
          },
        },
        [
          _vm.dialogAddOrEditVisible
            ? _c("AddOrEditQuestionAnswerInfo", {
                attrs: {
                  dataInfo: _vm.addOrEditModel,
                  questionId: _vm.questionId,
                },
                on: {
                  addSuccess: function ($event) {
                    _vm.getQuestionnaireQuestionAnswerList(),
                      (_vm.dialogAddOrEditVisible = false)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("el-image", {
        ref: "preview",
        staticStyle: { width: "0px", height: "0px" },
        attrs: { "preview-src-list": _vm.goodsGalleryList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }