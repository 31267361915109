<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-button type="primary" plain @click="getQuestionnaireQuestionAnswerList">刷新</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.saveQuestionnaireQuestionAnswerInfo"
                        @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe v-loading="showLoading" :height="tableHeight">
                <el-table-column label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="questionAnswerName" label="问题答案名称" align="left"
                    min-width="120"></el-table-column>
                <el-table-column prop="questionAnswerContent" label="问题答案提示内容" align="left"
                    min-width="240"></el-table-column>
                <el-table-column label="提示图片" align="center" width="90">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showContentHandle(scope.row, 1)"
                            v-if="scope.row.list && scope.row.list.length">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="sort" label="排序" align="center" width="90" sortable></el-table-column>
                <el-table-column prop="createTime" label="添加时间" align="center" width="140">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="90" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateQuestionnaireQuestionAnswerInfo">
                            <el-button class="success" icon="el-icon-edit-outline" type="text"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeQuestionnaireQuestionAnswerInfo">
                            <el-popconfirm title="您确定要删除么？" icon="el-icon-info" icon-color="red"
                                @confirm="clickDelInfo(scope.row, scope.$index)">
                                <el-button icon="el-icon-delete" slot="reference" type="text"
                                    class="danger"></el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <show-content v-model='dialogSeeContent' :title='rowInfo.title' :content='rowInfo.content'></show-content>

        <el-dialog :title="addOrEditTitle" :visible.sync="dialogAddOrEditVisible" :modal="false"
            :destroy-on-close="true" fullscreen>
            <AddOrEditQuestionAnswerInfo v-if="dialogAddOrEditVisible"
                @addSuccess="getQuestionnaireQuestionAnswerList(), dialogAddOrEditVisible = false"
                :dataInfo="addOrEditModel" :questionId="questionId" />
        </el-dialog>

        <!-- 图片 -->
        <el-image ref="preview" style="width: 0px;height: 0px;" :preview-src-list="goodsGalleryList"></el-image>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import { getList } from '@/tools/getList.js'
import questionnaireManager from '@/request/questionnaireManager.js'
import ShowContent from '@/components/common/subgroup/ShowContent.vue';
import AddOrEditQuestionAnswerInfo from '@/components/page/questionnaire/question/sub/AddOrEditQuestionAnswerInfo.vue'
export default {
    // import引入的组件需要注入到对象中才能使用
    props: ['questionId'],
    mixins: [getList],
    components: {
        ShowContent,
        AddOrEditQuestionAnswerInfo
    },
    data() {
        // 这里存放数据
        return {
            showLoading: true,
            dialogSeeContent: false,
            rowInfo: {},


            // 添加修改
            addOrEditTitle: '',
            addOrEditModel: null,
            dialogAddOrEditVisible: false,

            goodsGalleryList: [],
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.handlePermission()
        this.getQuestionnaireQuestionAnswerList()
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        async getQuestionnaireQuestionAnswerList() {
            try {
                this.showLoading = true
                let data = await questionnaireManager.questionnaireQuestionAnswerList({
                    questionId: this.questionId
                })
                if (data.code == 200) {
                    this.tableData = data.data
                }
            } catch (error) {
                //
            } finally {
                this.showLoading = false
            }
        },
        onAdd() {
            this.addOrEditModel = null
            this.addOrEditTitle = '修改问卷答案'
            this.dialogAddOrEditVisible = true
        },
        clickEditInfo(row) {
            this.addOrEditModel = row
            this.addOrEditTitle = '修改问卷答案'
            this.dialogAddOrEditVisible = true
        },
        async clickDelInfo(row, index) {
            try {
                let { code, message } = await questionnaireManager.removeQuestionnaireQuestionAnswerInfo({
                    id: row.id,
                })
                if (code === 200) {
                    this.$message({ message: message, type: 'success', })
                    this.delRefresh(index)
                }
            } catch (error) {
                console.log(error)
            }
        },
        showContentHandle(row, type) {
            switch (type) {
                case 1:
                    {
                        this.goodsGalleryList = row.list.map(obj => {
                            return obj.sourceImg
                        })
                        if (this.goodsGalleryList.length) {
                            this.$nextTick(() => {
                                this.$refs.preview.clickHandler()
                            })
                        }
                    }
                    break;

                default:
                    break;
            }
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped></style>
